@font-face {
  font-family: "ISTOK";
  src: url("./fonts/IstokWeb-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: "ISTOK" !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.smtv--quoteenligne-container{
  background-image: url("./back.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #3050C3;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius:50px;
    box-shadow: 2px 2px 2px 2px #e5e5ee;

}


.smtv--quoteenligne-container-back{
  background-color: #3050C3;
  min-height:30vh;
  border-bottom-left-radius: 30%;
  border-bottom-right-radius:30%;
  
}
.smtv--quoteenligne-card{
  min-height: 6vw;
  border-radius: 30px !important;
  margin: 35px 35px 0px 35px;
  width: 80%;
    box-shadow: 2px 2px 2px 2px #e5e5ee;

 
}
.smtv--quoteenligne-container {
  h1.ant-typography{
    font-size: 2rem !important;
  }
  h2.ant-typography{
    font-size: 1.5rem !important;
  
  }
  h4.ant-typography{
    font-size: 1rem !important;
  }
  
}
.smtv--quoteenligne-card-img{
  width:8vw;
  height: 8vw;
}
.smtv--quoteenligne-header-blue{
  min-height: 10vh;
  
  align-items: center;
  display: flex;
  text-align: center; 
}
.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after{
  height: 2px !important;
  background-color: #3050C3;
}
.ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after{

  height: 2px !important;
  background-color: #3050C3;
}
.ant-form-item-label label{
  color: #3050C3 !important;
  font-weight: bold !important;
  font-size: 1rem !important;
}
.ant-input{
  border: 3px solid #3050C3 !important;
  min-height: 50px !important;
  font-size: 1rem !important;
  color: #3050C3 !important;
}
.devis--input{
  width: 90%;
  float: left;
}

.title-item{
  margin-left: 10px;
  font-size: 1rem;
  color: #3050C3;
  font-weight: bold;
}
.smtv--quoteenligne-header{
  min-height: 15vh;
  border-bottom-left-radius:  80%;
  border-bottom-right-radius: 80%;
  z-index: 1000;
  background-color: #fff !important;
  align-items: center;
  display: flex;
  text-align: center;
  box-shadow: 2px 2px 2px 2px #e5e5ee;
}
.smtv--quoteenligne-header-big{
  min-height: 10vh;
  z-index: 1000;
  background-color: #fff !important;
  align-items: center;
  display: flex;
  text-align: center;
  box-shadow: 2px 2px 2px 2px #e5e5ee;
}
.smtv--quoteenligne-header-big2{
  min-height: 5vh;
  z-index: 1000;
  background-color: #fff !important;
  align-items: center;
  display: flex;
  text-align: center;
  box-shadow: 2px 2px 2px 2px #e5e5ee;
  border-top-left-radius:  10%;
  border-top-right-radius: -80%;
}
.smtv--devisenligne-button{
  background-color: #fff !important;
  color: #3050C3 !important;
  font-weight: bold !important;
  font-size: 1.1rem !important;
  min-height: 3vw !important;
  width: 100% !important;
  border-radius: 15px !important;
  @media only screen and (max-device-width: 758px){
    min-height: 5vw !important;

  }

}
.smtv--devisenligne-button-red{
  background-color: #C63D35 !important;
  color: #fff !important;
  font-weight: bold !important;
  font-size: 1.1rem !important;
  min-height: 3vw !important;
  width: 100% !important;
  text-transform: uppercase !important;
  border-radius: 15px !important;
  @media only screen and (max-device-width: 758px){
    min-height: 5vw !important;

  }

}
.devis--btn-blue{
  background-color: #3050C3 !important;
  font-weight: bold !important;
  font-size: 1.1rem !important;
  color: #fff;
  min-height: 4vh;
  min-width: 20vw !important;
}
.devis-enligne-img-footer{
  width: 3vh;
  height: 3vh;
}
.steps-content {
  margin-top: 16px;
  padding-top: 20px;
  text-align: center;
  background-color: #fafafa;
  border: 2px dashed #e9e9e9;
  border-radius: 2px;
}

.steps-action {
  margin-top: 24px;
  margin-bottom: 10px;
}

